import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import InstagramFeed from "./InstagramFeed";
class BlogRoll extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <div>
        <InstagramFeed />
        <div className="blogroll">
          {posts &&
            posts.map(({ node: post }) => (
              <div key={post.id}>
                <article className="blogroll__post">
                  <header>
                    {post.frontmatter.featuredimage ? (
                      <div className="blogroll__image">
                        <Link className="blogroll__title" to={post.fields.slug}>
                          <Img
                            fluid={{
                              ...post.frontmatter.featuredimage.childImageSharp
                                .fluid,
                              aspectRatio: 1.618,
                            }}
                            alt={`featured image thumbnail for post ${post.frontmatter.title}`}
                          />
                        </Link>
                      </div>
                    ) : null}
                    <Link className="blogroll__title" to={post.fields.slug}>
                      <h1>{post.frontmatter.title}</h1>
                    </Link>
                  </header>
                  <p className="blogroll__excerpt">{post.excerpt}</p>
                  <Link className="blogroll__readmore" to={post.fields.slug}>
                    Read More &rarr;
                  </Link>
                  <p className="blogroll__meta">
                    {post.frontmatter.date} &bull; Anthony Simpson
                  </p>
                </article>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

const blogRoll = () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 140)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "DD MMMM YYYY")
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 500, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
);

export default blogRoll;
