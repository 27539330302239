import React from "react";
const FEED_URL = "https://feeds.behold.so/3BVYByfGKAI1B7CzTf4f";

const InstagramFeed = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      posts: [],
    };
  }

  async componentDidMount() {
    const feed = await fetch(FEED_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const { posts } = await feed.json();

    console.log(posts);

    this.setState((state) => ({
      ...state,
      loading: false,
      posts,
    }));
  }

  render() {
    const { posts } = this.state;
    return (
      <div>
        <h2>Latest Instagram Reels</h2>
        <section className="instagram-feed">
          {posts &&
            posts.map((post) => (
              <a
                className="instagram__post"
                href={post.permalink}
                target="_blank"
              >
                <img src={post.sizes.small.mediaUrl} alt={post.id} />
              </a>
            ))}
        </section>
      </div>
    );
  }
};

export default InstagramFeed;
