import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'

import '../../styles/pages/blog.scss'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <h1>Blog</h1>
        <BlogRoll />
      </Layout>
    )
  }
}
